<template>
  <div class="row login-pane">
    <div class="container-fluid col-auto">
      <form class="bg-light border rounded form-signin p-3 mx-auto" v-on:submit.prevent="login">
        <Logo full_logo="true" id="crest" class="d-block p-4"></Logo>
        <div class="alert alert-danger" role="alert" v-if="showError">
          There was an error logging in.
        </div>
        <div v-if="authenticating" class="d-flex justify-content-center m-4">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <label for="inputUser"
               class="sr-only">Username</label>
        <input v-model="username" type="text" id="inputUser" class="form-control m-1" placeholder="Username">
        <label for="inputPassword" class="sr-only">Password</label>
        <input v-model="password" type="password" id="inputPassword" class="form-control m-1" placeholder="Password" required="">
        <div class="checkbox mb-2">
          <label>
            <input type="checkbox" value="remember-me" v-model="rememberMe" v-on:change="rememberMeChanged"> Remember Me
          </label>
        </div>
        <button class="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
        <div class="row">
          <div class="col-6 mt-2">
            <router-link to="/forgotPassword">Forgot Password</router-link>
          </div>
          <div class="col-6 text-right mt-2">
            <router-link to="/signUp">Sign Up</router-link>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AuthService from "@/common/auth.service";
import Logo from '@/components/Logo';

const rememberMeKey = "login_form";

export default {
  components: {Logo},
  mounted() {
    // see if they've opted to reload their credentials
    let loginForm = localStorage.getItem(rememberMeKey)
    if (loginForm) {
      let creds = JSON.parse(loginForm)
      this.username = creds.username
      this.password = creds.password
      this.rememberMe = creds.rememberMe
    }
  },
  data: function () {
    return {
      username: '',
      password: '',
      rememberMe: false,
      showError: false,
      authenticating: false
    }
  },
  methods: {
    rememberMeChanged() {
      // if they value is set to not remember clear whatever may be saved
      if (!this.rememberMe) {
        localStorage.removeItem(rememberMeKey)
      }
    },
    login() {
      // if they've opted to save credentials save them to localstorage
      if (this.rememberMe) {
        localStorage.setItem(rememberMeKey, JSON.stringify(this.$data))
      }
      console.log('Inactivity' + (60 * window.__env.api.inactivityTime));

      this.authenticating = true;
      AuthService.login(this.username, this.password)
      .then(data => {
        // save details of the token and user so we can store them client side.
        localStorage.setItem("token", data.token);
        localStorage.setItem("user", JSON.stringify(data));
        this.$router.push("home");
        location.reload(true);
      }).catch(err => {
        this.notifyError(err.response.data)
        this.showError = true;
      }).finally(() => {
        this.authenticating = false;
      })
    }
  }
};
</script>
<style scoped>
#crest{
  max-width: 400px;
  left: 50%;
}
.login-pane {
  margin-top: 10rem;
}
</style>